import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CSVLink } from "react-csv";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch
  // TextField
} from "@material-ui/core";
import addWeeks from 'date-fns/addWeeks';
import addDays from 'date-fns/addDays'
import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DateRangePicker from '@mui/lab/DateRangePicker';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import moment from "moment"
import Box from '@mui/material/Box';
import axios from "axios";
import SubCard from "../../ui-component/cards/SubCard";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography } from "@mui/material";
import { getDecryptDataFromLocal } from "../../utils/encrypt";
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  root: {
    width: "100%",
  },
  containerTable: {
    maxHeight: 440,
  },
}));
function getWeeksAfter(date, amount) {
  return date ? addDays(date, amount) : undefined;
}

export default function NewProductWiseReport() {
  const classes = useStyles();
  const DashboardPhoneNumber = getDecryptDataFromLocal('phoneNumber')

  const [data, setData] = useState([]);
  const [value, setValue] = React.useState([null, null]);
  const [value1, setValue1] = React.useState([null, null]);
  const [loading, setLoading] = useState(false)
  const [loadingOld, setLoadingOld] = useState(false)
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [showDownloadButtonOld, setShowDownloadButtonOld] = useState(false);
  const [oldData, setOldData] = useState([])
  const [brandData, setBrandData] = useState([]);
  const [filterType, setFilterType] = useState('');
  const [headerValue,setHeaderValue] = useState([])
  const [orderType, setOrderType] = useState('All');
  const [cancelled, setCancelled] = useState(false)
  const discountArr = ['Prepaid Discount', 'Base Discount', 'Flash Discount', 'Discount', 'Lightening Discount', 'Combo Discount', 'Coupon Discount', 'Festival Coupon', 'Festival Discount', 'Gold club Discount (9 %)', 'Bronze club Discount (3 %)', 'Silver club Discount (6 %)', 'Platinum club Discount (9 %)', 'codCharge']
  const oldHeader = [
    { label: "Id", key: "_id" },
    { label: "Status", key: "status" },
    { label: "New Id", key: "numericOrderId" },
    { label: "Recent ShipRocket OrderIds", key: "recentShipRocketOrderIds" },
    { label: "Created At", key: "createdAt" },
  ]
  const headers = [
    { label: "Id", key: "_id" },
    { label: "Unique Seller ID", key: "uniqueSellerId" },
    { label: "AWB Number", key: "awbNumber" },
    // { label: "Phone Number", key: "phoneNumber" },
    { label: "Customer Name", key: "customerName" },
    { label: "Category", key: "category" },
    { label: "MRP", key: "mrp" },
    { label: "EANCode", key: "EANCode" },
    { label: "Margin", key: "margin" },
    { label: "Cash Back Discount", key: "cashBackDiscount" },
    { label: "Numeric Order Id", key: "orderId" },
    { label: "Group Order Id", key: "groupedOrderId" },
    { label: "RTO Date", key: "RTOdate" },
    { label: "Shipped Date", key: "shippedDate" },
    { label: "Order Date", key: "orderDate" },
    { label: "Delivered Date", key: 'deliveredDate' },
    { label: "Order Value", key: "orderValue" },
    { label: "products Count", key: "productsCount" },
    { label: "Product Name", key: "productName" },
    { label: "Product Quantity", key: "productQuantity" },
    { label: "Product Type", key: "productType" },
    { label: "Total Payable Amount", key: "totalPayableAmount" },
    { label: "Remaining Amount For Cod", key: "remainingAmountForCod" },
    { label: 'Payment Method', key: 'paymentMethod' },
    { label: "Order Status", key: "status" },
    { label: "Seller Id", key: "sellerId" },
    { label: "Retail/Non-Retail", key: "isRetail" },
    { label: "GST Number", key: "gstNumber" },
    { label: "COD Price", key: 'codCharge' },
    { label: "Festival Coupon Discount", key: "festivalCouponDiscountAmount" },
    { label: "MemberShip Discount Percent In Decimal", key: "memberShipDiscountPercentInDecimal" },
    { label: "CashBack Discount", key: "cashBackDiscount" },
    { label: "Combo Discount", key: "comboDiscounts" },
    { label: "Prepaid Discount", key: "Prepaid Discount" },
    { label: "Prepaid Collected Amount", key: "prepaidCollectedAmount" },
    { label: "BMF Dicsount", key: "BMFdicsount" },
    { label: "CAC Dicsount", key: "CACdiscount" },
    { label: "check with COD", key: "check with COD" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Pin Code", key: "pincode" },
    { label: "agent Id", key: "agentId" },
    { label: "Splitted From", key: "splittedFrom" },
    { label: "Shipping Method", key: 'shippingMethod' },
    { label: "Source", key: 'source' },

    { label: "Brand", key: 'brandName' },
    { label: "Tax Rate", key: 'taxRate' },
    { label: "Replacement Order", key: 'isReplacementOrder' },
    { label: "CN Number", key: 'cnNumber' },
    { label: "Invoice Order", key: 'invoiceOrder' },
    { label: "Uc Invoice Order Id 1", key: 'ucInvoiceId1' },
    { label: "Uc Invoice Order Id 2", key: 'ucInvoiceId2' },
    { label: "Refunded Amount", key: 'refundedAmount' },


  ];
  useEffect(()=>{
    if(['9719836187','7001029850','9315405080','7827815759','9268096291','7532035127','8279461474','8374454823','9907125180','8076833686','9999957559','7701974309','8826956878'].includes(DashboardPhoneNumber)) { 
      setHeaderValue(headers)
    }else{
      let temp =  headers.filter((item)=>item.key!='phoneNumber')
      setHeaderValue(temp)
    }
  },[])


  const uploadDate = () => {
    setShowDownloadButton(false)
    setLoading(true);
    const reqBody = {
      startDate: new Date(value[0]),
      endDate: new Date(value[1]),
      filterType: filterType,
      orderType: orderType
    };
    if (cancelled) {
      reqBody.status = 2
    }
    console.log('reqBody to get ProductWise Report', reqBody);

    axios
      .post(process.env.REACT_APP_PATH + "/role3/productWiseReportV3", reqBody)
      .then((res) => {
        console.log(res.data)
        let newObj = [];
        res.data.data.forEach((row, index) => {

          /*  if (row.isRetail) {
             row.isRetail = 'Retail Pack'
           } else {
             row.isRetail = 'ECO Pack'
           } */

          // status
          if (row?.status === 2) {
            row.status = "Canceled"
          } else if (row?.status === 3) {
            row.status = "Complete"
          } else if (row?.status === 4) {
            row.status = "RTO"
          } else if (row?.status === 8) {
            row.status = "Splitted"
          } else if (row?.status === 6) {
            row.status = "Undelivered"
          } else {
            if (row?.status === 1 && row?.awbNumber !== undefined) {
              row.status = "In Transit"
            } else {
              row.status = "Processing"
            }
          }

          // if (row.status == "1") {
          //   row.status = 'In Transit'
          // } else if (row.status == '2') {
          //   row.status = "Cancelled"
          // } else if (row.status == "3") {
          //   row.status = 'Delievered'
          // } else if (row.status == '4') {
          //   row.status = 'RTO'
          // } else if (row.status == '6') {
          //   row.status = 'Undelivered'
          // } else {
          //   row.status = "No Status Found"
          // }

          if (row.paymentMethod == 1) {
            row.paymentMethod = "Cash"
          } else if (row.paymentMethod == 2) {
            row.paymentMethod = "Online"
          } else if (row.paymentMethod == 3) {
            row.paymentMethod = "Rupify"
          } else if (row.paymentMethod == 6) {
            row.paymentMethod = "Partial Pay"
          }

          if (row?.awbNumbers != undefined && Array.isArray(row?.awbNumbers)) {
            row.awbNumbers.unshift('')
            row.awbNumberStr = row?.awbNumbers.join()
          }
          if (row?.purchseOrders != undefined && Array.isArray(row?.purchseOrders)) {
            // row.purchseOrders.unshift('')

            row.purchseOrders.forEach((item, index) => {
              row[`purchaseOrder${index + 1}`] = item;

            })
          }


          if (row?.poDiscount != undefined && Array.isArray(row?.poDiscount)) {
            // row.poDiscount.unshift('')

            row.poDiscount.forEach((item, index) => {
              row[`poDiscount${index + 1}`] = item;

            })
          }

          if (row?.poQuantity != undefined && Array.isArray(row?.poQuantity)) {
            // row.poQuantity.unshift('')

            row.poQuantity.forEach((item, index) => {
              row[`poQuantity${index + 1}`] = item;

            })
          }


          if (row?.orderDate && row?.orderDate !== '') {
            row.orderDate = moment(new Date(row.orderDate)).format("DD/MM/YYYY hh:mm:ss A");

          } else {
            row.orderDate = ''

          }

          if (row?.RTOdate && row?.RTOdate !== '') {
            row.RTOdate = moment(new Date(row.RTOdate)).format("DD/MM/YYYY hh:mm:ss A");

          } else {
            row.RTOdate = ''

          }

          if (row?.shipRocketCreatedAt && row?.shipRocketCreatedAt !== '') {
            row.shipRocketCreatedAt = moment(new Date(row.shipRocketCreatedAt)).format("DD/MM/YYYY hh:mm:ss A");

          } else {
            row.shipRocketCreatedAt = ''

          }

          if (row?.shippedDate && row?.shippedDate !== '') {
            row.shippedDate = moment(new Date(row.shippedDate)).format("DD/MM/YYYY hh:mm:ss A");

          } else {
            row.shippedDate = ''

          }
          if (row?.deliveredDate && row?.deliveredDate !== '') {
            row.deliveredDate = moment(new Date(row.deliveredDate)).format("DD/MM/YYYY hh:mm:ss A");

          } else {
            row.deliveredDate = ''

          }

          if (row?.brandId) {
            let brandObj = brandData.filter((data) => {
              return data._id === row.brandId
            })
            row.brandName = brandObj[0].name;
          } else {
            row.brandName = row?.brandName
          }
          // if (row?.discountBreakupInfo?.length > 0) {
          //   let obj = (Object.assign({}, ...row?.discountBreakupInfo || []))
          //   let sum = 0;
          //   let objArr = Object.keys(obj)
          //   for (let i = 0; i < objArr.length; i++) {
          //     if (!discountArr.includes(objArr[i])) {
          //       sum = sum + obj[objArr[i]]
          //     }
          //   }
          //   if (sum > 0) {
          //     row.allOtherDis = sum
          //   }
          //   row.prepaidDiscount = obj?.['Prepaid Discount'] || 0;
          //   row.basePrice = obj?.['Base Discount'] || 0;
          //   row.flashDiscount = obj?.['Flash Discount'] || 0;
          //   row.flashWithBaseDiscount = obj?.Discount || 0;
          //   row.lightningDiscount = obj?.['Lightening Discount'] || 0;
          //   row.comboDiscount = obj?.['Combo Discount'] || 0
          //   row.couponDiscount = (obj?.['Coupon Discount'] || 0) + (obj?.['Festival Coupon'] || 0) + (obj?.['Festival Discount'] || 0);
          //   row.MembershipDiscount = Math.max((obj?.['Gold club Discount (9 %)'] || 0), (obj?.['Bronze club Discount (3 %)'] || 0), (obj?.['Silver club Discount (6 %)'] || 0), (obj?.['Platinum club Discount (9 %)'] || 0))
          // } else {
          //   if (row?.discountBreakUpObj !== null || row?.discountBreakUpObj !== undefined || Object.keys(row.discountBreakUpObj).length !== 0) {
          //     row.basePrice = row?.discountBreakUpObj?.baseDiscount || 0;
          //     row.flashDiscount = row?.discountBreakUpObj?.flashDiscount || 0;
          //     row.flashWithBaseDiscount = row?.discountBreakUpObj?.Discount || 0;

          //     row.comboDiscount = row?.discountBreakUpObj?.['Combo Discount'] || 0
          //     row.lightningDiscount = row?.discountBreakUpObj?.['Lightning Discount'] || 0;
          //     try {
          //       row.couponDiscount = (row.discountBreakUpObj['Coupon Discount'] ? row.discountBreakUpObj['Coupon Discount'] : 0) + (row.discountBreakUpObj['Festival Coupon'] ? row.discountBreakUpObj['Festival Coupon'] : 0);
          //     } catch (err) {
          //       console.log("item si ", row, err)
          //     }

          //     row.MembershipDiscount = Math.max((row?.discountBreakUpObj?.['Gold club Discount (9 %)'] || 0), (row?.discountBreakUpObj?.['Bronze club Discount (3 %)'] || 0), (row?.discountBreakUpObj?.['Silver club Discount (6 %)'] || 0), (row?.discountBreakUpObj?.['Platinum club Discount (9 %)'] || 0))
          //   }
          // }

          // if (row.discountBreakUpObj != null) {
          //   row.finalCouponDiscount = (row.discountBreakUpObj['Festival Coupon'] || 0) + (row.discountBreakUpObj['Coupon Discount'] || 0)
          // }
          // if (row.discountBreakUpObj2 != null) {
          //   // row.FinalflashDiscount = (row.discountsObj['baseDiscount']) + (row.discountsObj['flashDiscount'])
          //   row.discountBreakUpObj2.discount2finalDiscount = (row.discountBreakUpObj2['Coupon Discount'] || 0) + (row.discountBreakUpObj2['Festival Coupon'] || 0) + (row.discountBreakUpObj2['orderIn3DaysOffer'] || 0) + (row.discountBreakUpObj2['First order offer'] || 0)
          //   // row.discountBreakUpObj2.FirstOrderOffer =  (row.discountBreakUpObj2['First order offer'] || 0)
          //   // if (row.discountBreakUpObj2['Discount'] == 0) {
          //   //   row.discountBreakUpObj2.discount2lightandDiscount = (row.discountBreakUpObj2['flashDiscount'] || 0) + (row.discountBreakUpObj2['baseDiscount'] || 0)
          //   // } else {
          //   //   row.discountBreakUpObj2.discount2lightandDiscount = (row.discountBreakUpObj2['Discount'] || 0)
          //   // }
          //   row.discountBreakUpObj2.discount2lightandDiscount = (row.discountBreakUpObj2['Discount'] || 0)
          //   row.discountBreakUpObj2.discount2membershipDiscount = (row.discountBreakUpObj2['Bronze club Discount (3 %)'] || 0) + (row.discountBreakUpObj2['Gold club Discount (9 %)'] || 0) + (row.discountBreakUpObj2['Silver club Discount (6 %)'] || 0)
          // }
        })


        setData(res.data.data)
        setLoading(false)
        setShowDownloadButton(true)
      })
      .catch((error) => {
        console.log("error", error)
        alert("Something went wrong")
        setLoading(false)
        setShowDownloadButton(false)
      });
  };


  const uploadOldDate = () => {
    setShowDownloadButtonOld(false)
    setLoadingOld(true);
    const reqBody = {
      startDate: new Date(value1[0]),
      endDate: new Date(value1[1]),
    };

    axios
      .post(process.env.REACT_APP_PATH + "/role3/oldOrderIdsReport", reqBody)
      .then(resp=>{
        if(resp?.data?.success){
          setOldData(resp?.data?.data)
          setShowDownloadButtonOld(true)
          setLoadingOld(false)
        }
      }).catch(err=>{
        console.log("error-------", err?.response)
      })
  };



  const findMonth = (month) => {
    console.log("findmonth", new Date(value[0]).getDate())
    let monthName = ''
    switch (month) {
      case 0:
        monthName = 'Januray'
        break;
      case 1:
        monthName = 'February'
        break;
      case 2:
        monthName = 'March'
        break;
      case 3:
        monthName = 'April'
        break;
      case 4:
        monthName = 'May'
        break;
      case 5:
        monthName = 'June'
        break;
      case 6:
        monthName = 'July'
        break;
      case 7:
        monthName = 'August'
        break;
      case 8:
        monthName = 'September'
        break;
      case 9:
        monthName = 'October'
        break;

      case 10:
        monthName = 'November'
        break;
      case 11:
        monthName = 'December'
        break;

      default:
        monthName = ''
        break;
    }
    return monthName
  }


  useEffect(() => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_PATH + "/role1/allBrands"
    }).then(res => {
      setBrandData(res.data.data)
    })
  }, []);

  return (
    <form className={classes.container} noValidate>
      <Grid container xs={12} spacing={4} item alignItems="center">
        <Grid xs={5} item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              // disablePast
              value={value}
              maxDate={getWeeksAfter(value[0], 44)}
              inputFormat="dd-MM-yyyy"
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>

        </Grid>
        <Grid xs={2} item >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              value={filterType}
              onChange={(e) => {
                console.log('filterType===>>>', e.target.value)
                setFilterType(e.target.value)
              }}
              name="department"
            >
              <MenuItem value={0}>Created At---</MenuItem>
              <MenuItem value={1}>Shipped At</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={2} item >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              value={orderType}
              onChange={(e) => {
                console.log('orderType===>>>', e.target.value)
                setOrderType(e.target.value)
              }}
              name="orderType"
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"ONLINE"}>ONLINE</MenuItem>
              <MenuItem value={"OFFLINE"}>OFFLINE</MenuItem>
              <MenuItem value={"BULK"}>BULK</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={2}>
          <FormControlLabel
            control={
              <Switch
                checked={cancelled}
                onChange={() => setCancelled(!cancelled)}
                name="checkedB"
                color="primary"
              />
            }
            label="Cancelled"
            labelPlacement="start"
          />
        </Grid>

        <Grid item xs={3}>
          {/* <Button
            variant="contained"
            style={{
              width: "100%",
            }}
            onClick={() => uploadDate()}
          >
            Submit
          </Button> */}
          <LoadingButton
            variant="contained"
            style={{
              width: "100%",
            }}
            onClick={() => uploadDate()}
            loadingIndicator="Loading..."
            loading={loading}
          >
            Submit
          </LoadingButton>
        </Grid>
        {
          showDownloadButton ? <Grid item xs={2}>
            <CSVLink filename={`${new Date(value[0]).getDate()}${findMonth(new Date(value[0]).getMonth())} - ${new Date(value[1]).getDate()}${findMonth(new Date(value[1]).getMonth())}ProductWise.csv`} data={data} headers={headerValue} style={{ textDecoration: 'none', border: '1px solid #999', padding: '10px 20px', backgroundColor: '#95c7f1' }}>
              Download
            </CSVLink>
          </Grid> : null
        }

      </Grid>
      <Grid container xs={12} spacing={4} item alignItems="center" marginTop={1}>
        <Grid item xs={12}>
          <Typography
            textAlign="left"
            style={{
              color: "#000",
              fontWeight: "bold",
              fontSize: "20px",
            }}>
            {"Old Order Report"}
          </Typography>
        </Grid>
        <Grid xs={4} item>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              // disablePast
              value={value1}
              maxDate={getWeeksAfter(value1[0], 44)}
              inputFormat="dd-MM-yyyy"
              onChange={(newValue) => {
                console.log("value1 ------------->", newValue)
                setValue1(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>

        </Grid>
        <Grid xs={3} item>
          <LoadingButton
            variant="contained"
            style={{
              width: "100%",
            }}
            onClick={() => uploadOldDate()}
            loadingIndicator="Loading..."
            loading={loadingOld}
          >
            Submit
          </LoadingButton>
        </Grid>
        {showDownloadButtonOld &&
          <Grid xs={4} item>
            <CSVLink 
            filename={`${new Date(value1[0]).getDate()}${findMonth(new Date(value1[0]).getMonth())} - ${new Date(value1[1]).getDate()}${findMonth(new Date(value1[1]).getMonth())}OldOrder.csv`} 
            data={oldData.map(item=>{
              return {
                ...item,
                createdAt: moment(item?.createdAt).format("DD/MM/YYYY"),
              }
            })} 
            headers={oldHeader} 
            style={{ 
              textDecoration: 'none', 
              border: '1px solid #999', 
              padding: '10px 20px', 
              backgroundColor: '#95c7f1' 
              }}>
              Download
            </CSVLink>

          </Grid>
        }

      </Grid>
    </form>
  );
}
